<template>
    <div v-if="$root.project" class="area-content-body projects-site-order">
        <div class="project-head" :class="{dirty:$root.isDirty}">
            <header-field-editor class="project-number-edit" :input-hint="'Projekt-Nr.'" v-model:data-source="$root.project.number" :allow-edit="false" />
            <div class="v-line"></div>
            <span class="headline">Baustellenordnung</span>
            <div>
                <span class="head-toolbar-button-compact" @click="generateDocument" title="Vorankündigung erstellen">description</span>
            </div>
            <attachments-button />
        </div>
        <div class="projects-notice-preview">
            <image-viewer ref="siteOrderPreview"
                            :attachment="{assignment:$root.project._id,assignmentType:'project',name:'Baustellenordnung.docx'}"
                            allowedTypes="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            :allowDownload="true"
                            :allowUpload="false"
                            :disableDrop="true"
                            style="height:calc(100% - 10px);"></image-viewer>
        </div>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import siteOrderApi from '../services/SiteOrderApi';
    import ImageViewer from '../components/image-viewer.vue';
    import attachmentApi from '../services/AttachmentApi';
    import notify from 'devextreme/ui/notify';
    import HeaderFieldEditor from '../components/header-field-editor.vue';
    

    export default defineComponent({
        components: {
            ImageViewer,
            HeaderFieldEditor
        },
        watch: {        
        },
        data() {
            return {
            };
        },
        async created() {
            if (this.$root.project && this.$route.params.id == this.$root.project._id) {    
                this.loading = false;            
            } else {
                this.loading = true;
                this.$root.loadProject(this.$route.params.id);
                this.loading = false;
            }
        },
        methods: {
            async generateDocument() {
                try {
                    let att = await siteOrderApi.generateDocument(this.$root.project._id);
                    await this.$refs.siteOrderPreview?.setAttachment(att);
                    
                    this.$root.toast("Baustellenordnung", [{
                        icon: "download",
                        hint: "Baustellenordnung herunterladen",
                        onClick: async (t) => {
                            await attachmentApi.download(att._id);
                            t.remove();
                        }
                    }], 5000);
                } catch {
                    notify("Fehler beim Erstellen der Unterlage", "error");
                }
            }
        }
    });
</script>


<style>
    .projects-site-order {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: minmax(0,1fr);
        grid-template-rows: max-content minmax(0,1fr);
    }

        .projects-notice .project-head {
            grid-template-columns: max-content max-content 1fr max-content;
        }

    .headline {
        font-size: 1.5rem;
        align-self: center;
        padding: 5px 15px;
    }

</style>

