<template>
    <div class="area-content-body main-data-organization-contain">
        <dx-text-box v-model:value="searchString" :show-clear-button="true" placeholder="Volltext Suche..."></dx-text-box>
        <div v-if="searchString" class="main-data-search-container">
            <div v-for="organization in searchResults" :key="organization._id" class="main-data-search-organization" @click.prevent.stop="selectOrganization(organization)">
                <div class="main-data-search-item-bar"><span class="material-symbols-outlined">business</span><span>{{organization.name}}</span></div>
                <div v-if="organization.eMail" class="main-data-search-item-bar"><span class="material-symbols-outlined">mail</span><span>{{organization.eMail}}</span></div>
                <div v-for="contact in organization.contacts" :key="contact._id" class="main-data-search-contact"  @click.prevent.stop="selectContact(contact)">
                    <div class="main-data-search-item-bar"><span class="material-symbols-outlined">person</span><span>{{contact.firstName||""}} {{contact.lastName||""}}</span></div>
                    <div v-if="contact.eMail" class="main-data-search-item-bar"><span class="material-symbols-outlined">mail</span><span>{{contact.eMail}}</span></div>
                    <div v-if="contact.phoneNumber" class="main-data-search-item-bar"><span class="material-symbols-outlined">phone</span><span>{{contact.phoneNumber}}</span></div>
                    <div v-if="contact.mobileNumber" class="main-data-search-item-bar"><span class="material-symbols-outlined">smartphone</span><span>{{contact.mobileNumber}}</span></div>
                </div>
            </div>
        </div>
        <DxDataGrid v-else ref="grid"
                    class="main-data-organizations-grid" :focused-row-enabled="true"
                    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
                    key-expr="_id"
                    @editing-start="organization=null"
                    :data-source="organizations"
                    v-model:selectedRowKeys="organization"
                    v-model:focused-row-key="focusedRowKey"
                    @saving="saving"
                    :columnChooser="gridColumnChooser"
                    :stateStoring="gridStateStoring"
                    :scrolling="{mode:'virtual',preloadEnabled:true}"
                    :pager="{showInfo:true}"
                    @selection-changed="selectionChanged">
            <DxFilterRow :visible="true"></DxFilterRow>



            <DxColumn data-field="name" caption="Firma" />
            <DxColumn data-field="zipCode" caption="PLZ" :visible="false" />
            <DxColumn data-field="city" caption="Ort" />
            <DxColumn data-field="streetAndNr" caption="Straße" :visible="false" />
            <DxColumn data-field="eMail" caption="E-Mail" :visible="false" />



            <DxSelection mode="single" />
            <DxEditing :allow-adding="true"
                       :allow-deleting="true"
                       new-row-position="last"
                       :use-icons="true"
                       mode="cell" />
            <DxToolbar>

                <DxItem location="before"
                        template="exportDataTemplate"/>                
                <DxItem name="addRowButton"></DxItem>
                <DxItem name="columnChooserButton" />
            </DxToolbar>

            <template #exportDataTemplate>
                <DxButton
                          text="Export"
                          @click="exportData"
                          />
            </template>

        </DxDataGrid>

        <router-view v-show="organization" v-slot="{ Component }" class="main-data-data">
            <transition name="fade">
                <component ref="organization" :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { parse } from '@tinyhttp/content-disposition'
    import DxDataGrid,{
        DxColumn,        
        DxSelection,        
        DxEditing,    
        DxHeaderFilter,
        DxSearchPanel,
        DxFilterRow,
        DxToolbar,
        DxItem        
    } from 'devextreme-vue/data-grid';
    import listApi from '../services/ListApi';
    import DxSearch from 'devextreme-vue/data-grid';    
    import organizationApi from '../services/OrganizationApi';
    import { DxTextBox } from 'devextreme-vue';
    import { DxButton } from 'devextreme-vue/button';
  
    export default defineComponent({
        components: {
            DxDataGrid,
            DxSearch,
            DxColumn,
            DxSelection,
            DxSearchPanel,
            DxHeaderFilter,
            DxFilterRow,
            DxEditing,
            DxTextBox,
            DxToolbar,
            DxItem,
            DxButton
        },
        data() {
            return {                
                organizations: [],
                organization: null,
                searchString: null,
                searchResults: [],
                gridStateStoring: this.getStateStoring(),
                gridColumnChooser: { enabled: true }
            };
        },
        watch: {
            organization() {
                this.$router.push("/MainData/Organizations/" + this.organization)
            },
            async searchString() {
                if (this.searchString) {
                    this.searchResults = [];
                    let result = await organizationApi.search("**:"+this.searchString);
                    for (let organization of result.organizations) {
                        this.searchResults.push(organization);
                        organization.contacts = result.contacts.filter(c => c.organizationId == organization._id);
                    }
                }
            }
        },
        async created() {
            await this.fetchData();
        },
        methods: {
            // Todo: Move to backend
            async fetchData() {
                this.organizations = await organizationApi.getAll();
                if (this.$route.params?.id) {
                    this.organization = this.$route.params?.id
                } else if (this.organizations.length > 0) {
                    this.organization = this.organizations[0]._id
                }
            },
            selectOrganization(organization) {
                this.$router.push("/MainData/Organizations/" + organization._id);
            },
            selectContact(contact) {
                this.$router.push("/MainData/Organizations/" + contact.organizationId + "/" + contact._id)
            },
            async selectionChanged(e) {
                this.organization = e.selectedRowKeys[0];
            },
            getStateStoring() {
                let key = "organizations-grid:" + this.$root.currentUser;
                return {
                    enabled: true,
                    type: "custom",
                    async customLoad() {
                        let settingsList = await listApi.getByName("sys_settings");
                        let item = await listApi.getListItemByValue(settingsList._id, key);
                        if (item)
                            return JSON.parse(item.data);
                        return null;
                    },
                    async customSave(e) {
                        let settingsList = await listApi.getByName("sys_settings");
                        let item = await listApi.getListItemByValue(settingsList._id, key);
                        if (item) {
                            let prev = item.data
                            item.data = JSON.stringify({ columns: e.columns });
                            if (prev == item.data) {
                                return item;
                            }
                            await listApi.saveItem(item);
                        }
                        else {
                            return await listApi.saveItem({ listId: settingsList._id, value: key, data: JSON.stringify({ columns: e.columns }) });
                        }
                    }
                }
            },
            async saving(e) {
                for (let change of e.changes) {
                    let item = this.organizations.find(t => t._id === change.key);
                    if (change.type === "update") {
                        Object.assign(item, change.data);
                        await organizationApi.save(item);
                    }
                    else if (change.type === "insert") {
                        item = change.data;
                        item = await organizationApi.save(item);
                        this.organization = item._id;
                    }
                    else if (change.type === "remove") {
                        await organizationApi.delete(change.key)
                    }
                    else {
                        console.error("DONT KNOW", change.type)
                    }
                }
                if (e.changes.some(c => c.type === 'insert')) {
                    this.organizations = await organizationApi.getAll();
                }
            },
            async exportData() {
                let exportResult = await organizationApi.export();     

                this.$root.toast("Stammdaten Export", [{
                    icon: "download",
                    hint: "Exceldatei herunterladen",
                    onClick: async () => {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style.display="none";
                        a.download = parse(exportResult.headers['content-disposition']).parameters.filename;
                        a.href = URL.createObjectURL(exportResult.data);
                        a.click();
                        a.remove();                
                    }
                }], 5000);              
            }
        },
       
    });
</script>

<style>
    .main-data-organization-contain {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: max-content minmax(0,1fr);
        grid-gap: 10px;
        grid-auto-flow: column;
    }

    .main-data-organizations-grid {
        padding: 10px;
        background: #2a2a2a;
    }

    .main-data-data{
        grid-row: 1/span 2;
    }

    .main-data-search-organization {
        border: 1px solid var(--color-background-light);
        border-radius: var(--default-border-radius);
        padding: 5px 10px;
        margin: 10px 0;
    }

    .main-data-search-item-bar {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 10px;
    }

    .main-data-search-contact {
        padding: 5px;
        margin: 5px 0;
        border-radius: var(--default-border-radius);
        background: var(--color-background-light);
    }

    .main-data-search-container {
        overflow: auto;
        padding-right: 10px;
    }
</style>
