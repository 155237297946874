import axios from './Axios';
import { addProcess } from './Processor';

class SiteOrderApi {
    async generateDocument(id) {
        let process = addProcess("Generating SiteOrderDoc: " + id);
        let attachment = await axios.post(`/api/ProjectSiteOrder/${id}/GenerateDocument`).finally(() => process());
        return attachment.data;
    }
}

export { SiteOrderApi }
let siteOrderApi = new SiteOrderApi();
export default siteOrderApi