<template>
    <DxLookup v-if="!view" :data-source="dataSource" :display-expr="displayExpr" value-expr="_id" v-model:value="editableValue" :showClearButton="showClearButton" :showCancelButton="false">
        <DxDropDownOptions :show-title="false" :hide-on-outside-click="true" :min-width="minWidth" />
    </DxLookup>
    <div v-else>{{text}}</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import {
        DxLookup,
        DxDropDownOptions
    } from 'devextreme-vue/lookup';
    
    import organizationApi from "../services/OrganizationApi";
import DataSource from 'devextreme/data/data_source';
import { luceneConverter } from '../services/Util';

    let cache = {};
    export default defineComponent({
        components: {
            DxLookup,
            DxDropDownOptions
        },
        props: ['value', 'showOrganization', 'onValueChanged', 'view', 'display', 'organizationFilter', 'showClearButton', 'minWidth'],
        data() {
            return {
                dataSource: null,
                editableValue: null,
                text: null,
                item:null
            };
        },
        watch:{
            async editableValue(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$emit('update:value', this.editableValue);
                    if (this.onValueChanged)
                        this.onValueChanged(this.editableValue);
                }                
                if (!this.dataSource || !this.editableValue) {
                    this.text= this.displayExpr(null)
                    return;
                }          
                let item = await this.dataSource.loadSingle(this.editableValue)
                this.text = this.displayExpr(item);
                cache[this.editableValue] = item;                
            }
        },
        async created() {
            
            this.dataSource = new DataSource({
                load: async (loadOptions) => {
                    if (loadOptions.filter) {
                        let query = luceneConverter.toLucene(loadOptions.filter);
                        let result = await organizationApi.search(query, loadOptions.take, loadOptions.skip, "Name,FirstName,LastName,OrganizationId_Name,EMail,PhoneNumber,MobileNumber")
                        return {
                            data: result.contacts, totalCount: result.totalContactHits
                        };
                    } else if (loadOptions.searchValue) {
                        let result = await organizationApi.search("**:" + loadOptions.searchValue, loadOptions.take, loadOptions.skip, "Name,FirstName,LastName,OrganizationId_Name,EMail,PhoneNumber,MobileNumber");
                        return {
                            data: result.contacts, totalCount: result.totalContactHits
                        }
                    } else {
                        let result = await organizationApi.search("*:*", loadOptions.take, loadOptions.skip, "Name,FirstName,LastName,OrganizationId_Name,EMail,PhoneNumber,MobileNumber");
                        return {
                            data: result.contacts, totalCount: result.totalContactHits
                        }
                    }
                },
                byKey: async (key) => {
                    if (key in cache)
                        return cache[key]
                    let result = await organizationApi.search("_id:" + key, 1, 0, "Name,FirstName,LastName,OrganizationId_Name,EMail,PhoneNumber,MobileNumber");
                    var retVal = result.totalContactHits ? result.contacts[0] : { _id: key, Name: "{contact not found}", FirstName: "", LastName: "{contact not found}", OrganizationId_Name: "",EMail:"",PhoneNumber:"",MobileNumber:""};
                    return retVal;
                },
                key: "_id"
            });
            if(this.organizationFilter){
                this.dataSource.filter(["OrganizationId_Id","=",this.organizationFilter]);
            }
            this.editableValue = this.value;
        },
        methods:{
            displayExpr(r){
                if (this.display)
                    return this.display(r);
                if (!r)
                    return null;
                if(this.showOrganization)
                    return `${r.FirstName} ${r.LastName} (${r.OrganizationId_Name})`;
                return r.FirstName + ' ' + r.LastName;
            }
        }
    });
</script>

<style>
</style>
