import { ref, watch } from 'vue'
import axios from './Axios';
import offlineService, { offlineOrganizationApi } from './OfflineService';
import { addProcess } from './Processor';
class OrganizationApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;
        this.cache = {}
        this.changeListeners = {};
        this.contactCache = {}
        window.Api.Organization = this;
    }
    async beforeSave(data) {
        if (Array.isArray(data))
            return await Promise.all(data.map(d => this.beforeSave(d)));
        if (data._id in this.cache)
            delete this.cache[data._id];
    }
    async afterLoad(data) {
        if (Array.isArray(data))
            return await Promise.all(data.map(d => this.afterLoad(d)));
        this.cache[data._id] = data;
    }
    async getAll() {
        console.log("Fetch organizations...");
        try {
            this.isLoading.value = true;
            let result = offlineService.checkIsOffline ? await offlineOrganizationApi.getAll() : (await axios.getBuffered('/api/organizations')).data;
            await this.afterLoad(result);
            this.isLoading.value = false;
            return this.attachWatchers(result);
        }
        catch (ex) {
            console.warn("Failed to fetch organizations",ex)
            return null;
        }
    }
    async get(id) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? offlineOrganizationApi.get(id) : (await axios.getBuffered('/api/organizations/' + id)).data;
        await this.afterLoad(result);
        this.isLoading.value = false;
        return this.attachWatchers(result);
    }
    async getProjectsInvolved(id) {
        this.isLoading.value = true;
        let data = (await axios.get(`/api/organizations/${id}/projectsInvolved`)).data;
        this.isLoading.value = false;
        return data;
    }
    async save(data) {
        // TODO: no offline yet
        this.isSaving = true;
        let process = addProcess("Save Organization: " + data.name);
        await this.beforeSave(data);
        let result = await axios.post('/api/organizations/', data).finally(() => process());
        this.cache[result.data._id] = result.data;
        this.isSaving = false;
        return result.data;
    }
    async delete(data) {
        // TODO: no offline yet
        let key = data;
        if (typeof (data) === 'object') {
            key = data._id;
        }
        let process = addProcess("Delete Organization: " + data.name);
        let result = await axios.delete('/api/organizations/' + key).finally(() => process());
        return result.data;
    }
    async beforeSaveItem(data) {
        if (Array.isArray(data))
            return await Promise.all(data.map(d => this.beforeSaveItem(d)));
        if (data._id in this.contactCache)
            delete this.contactCache[data._id];
    }
    async afterLoadItem(data) {
        if (Array.isArray(data))
            return await Promise.all(data.map(d => this.afterLoadItem(d)));
        this.contactCache[data._id] = data;
    }
    async getContacts() {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineOrganizationApi.getContacts() : (await axios.getBuffered('/api/organizations/contact/')).data;
        await this.afterLoadItem(result);
        this.isLoading.value = false;
        return result;
    }
    async getContact(id, noCache) {
        if (!noCache && id in this.contactCache)
            return this.contactCache[id];
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? offlineOrganizationApi.getContact(id) : (await axios.getBuffered('/api/organizations/contact/' + id)).data;
        await this.afterLoadItem(result);
        this.isLoading.value = false;
        return result;
    }
    async getOrganizationContacts(id) {
        // TODO: no offline yet
        this.isLoading.value = true;
        let result = await axios.getBuffered('/api/organizations/' + id + '/contacts');
        await this.afterLoadItem(result.data);
        this.isLoading.value = false;
        return result.data;
    }
    async search(query,take,skip,fields) {
        let params = "";
        if (typeof (take) === 'number') {
            params = "?" + new URLSearchParams({ skip:skip||0, take }).toString()
        }
        let result = await axios.getBuffered('/api/organizations/search/' + encodeURIComponent(query) + (fields ? ("/" + fields) : "") + params);
        return result.data;
    }
    async saveContact(data) {
        // TODO: no offline yet
        this.isSaving = true;
        let process = addProcess("Save Contact: " + data.name);
        this.beforeSaveItem(data);
        let result = await axios.post('/api/organizations/contact/', data).finally(() => process());
        this.contactCache[result.data._id] = result.data;
        this.isSaving = false;
        return result.data;
    }
    async deleteContact(data) {
        // TODO: no offline yet
        let key = data;
        if (typeof (data) === 'object') {
            key = data._id;
        }
        let process = addProcess("Delete Contact: " + data.name);
        let result = await axios.delete('/api/organizations/contact/' + key).finally(() => process());
        return result.data;
    }

    async createIndex() {
        this.isLoading.value = true;
        let result = (await axios.post("/api/organizations/createIndex")).data;
        console.log("[INDEX] created:", result);
        this.isLoading.value = false;
    }

    async export() {        
        this.isLoading.value = true;      
        let process = addProcess("Exporting organizations");
        let result = await axios.get("/api/organizations/export/", { responseType: 'blob' }).finally(() => process());
        this.isLoading.value = false;
        return result;
    }

    attachWatchers(data) {
        if (Array.isArray(data))
            return data.map(i => this.attachWatchers(i));
        let dataRef = ref(data);
        this.isLoading.value = false;
        watch(dataRef.value, async () => {
            if (this.isSaving)
                return;
            this.isDirty.value = true;
            this.isSaving = true;
            let newData = await this.save(dataRef.value);
            Object.assign(dataRef.value, newData);
            setTimeout(() => {
                this.isSaving = false;
            }, 1)
            this.isDirty.value = false;
        });
        return dataRef.value;
    }
}

export { OrganizationApi };

let organizationApi = new OrganizationApi();

export default organizationApi;