<template>
    <div :style="{'height':height}"
         class="image-viewer"
         :class="{'image-viewer-invalid':invalid,'image-viewer-over':over,'image-viewer-minimal':minimal,'image-viewer-alt':!hasImage&&isAltImage}"
         @click="viewerClick"
         @dragenter.stop.prevent="dragenter"
         @dragleave.stop.prevent="dragleave"
         @dragover.stop.prevent="dragover"
         @drop.stop.prevent="drop">
        <div class="image-viewer-buttons">
            <div v-if="allowUpload" @click.stop.prevent="openUpload" class="image-viewer-button material-symbols-outlined" title="Hochladen">upload</div>
            <div v-if="allowDownload" @click.stop.prevent="download" class="image-viewer-button material-symbols-outlined" title="Herunterladen">download</div>
            <div v-if="allowEdit && hasImage" @click.stop.prevent="edit" class="image-viewer-button material-symbols-outlined" title="Bearbeiten">edit</div>
            <div v-if="allowEdit && hasImage" @click.stop.prevent="crop" class="image-viewer-button material-symbols-outlined" title="Zuschneiden">crop</div>
            <div v-if="allowClear && hasImage" @click.stop.prevent="clear" class="image-viewer-button material-symbols-outlined" title="Löschen">delete</div>
            <div v-if="allowFullScreen && hasImage" @click.stop.prevent="fullscreen=!fullscreen" class="image-viewer-button material-symbols-outlined" title="Vollbild">fullscreen</div>
            <div v-for="(button,index) in buttons" v-bind:key="index" @click.stop.prevent="button.onClick" class="image-viewer-button material-symbols-outlined" :title="button.text">{{button.icon}}</div>
        </div>
        <div v-if="!disableDrop && !hasImage && !isAltImage" class="image-viewer-info-upload material-symbols-outlined">
            upload_file
        </div>
        <div v-else-if="hasImage && uploadedIcon" class="image-viewer-info-upload material-symbols-outlined">
            {{uploadedIcon}}
        </div>
        <div v-else-if="disableDrop && !hasImage && !isAltImage" class="image-viewer-info-upload material-symbols-outlined">
            image_not_supported
        </div>
        <div v-if="msg" class="image-viewer-info">{{msg}}</div>
        <span v-if="title">{{title}}</span>
        <div v-show="!editing" class="image-viewer-image" :style="{'background-image':'url('+url+')'}"></div>
        <dx-text-box v-if="dbAttachment && allowDescription && hasImage" class="image-viewer-desc" v-model:value="dbAttachment.description" placeholder="Beschreibung" />
        <div v-if="altDescription && isAltImage" class="image-viewer-desc image-viewer-desc-alt">{{altDescription}}</div>
        <img ref="editImg" v-show="editing" class="image-viewer-image-edit" :src="url" />
        <input ref="fileInput" type="file" :accept="acceptTypeString" hidden @input="fileInputChange" />
        <DxPopup v-model:visible="fullscreen"
                 :fullscreen="true"
                 :drag-enabled="false"
                 :hide-on-outside-click="true"
                 :show-close-button="false"
                 :show-title="false">
            <div class="image-viewer-image image-viewer-full" :style="{'background-image':'url('+url+')'}"></div>
            <DxToolbarItem v-if="showUploadInFullScreen" widget="dxButton"
                           toolbar="top"
                           location="after"
                           :options="popUpUploadButton" />
            <DxToolbarItem v-if="showDeleteInFullScreen" widget="dxButton"
                           toolbar="top"
                           location="after"
                           :options="popUpDeleteButton" />
        </DxPopup>
    </div>
</template>

<script lang="js">    
    import { MarkerArea } from 'markerjs2';
    import FilledRectangularBoxMarker from '../customMarkers/FilledRectangularBoxMarker';
    import FilledEllipseMarker from '../customMarkers/FilledElipseMarker';
    import { CropArea } from 'cropro';

    import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
    import { DxTextBox } from 'devextreme-vue/text-box';
    import { confirm } from 'devextreme/ui/dialog'
    import notify from 'devextreme/ui/notify';
    import { defineComponent } from 'vue';
    import attachmentApi from '../services/AttachmentApi';
    import { guid } from '../services/Util';
    import {baseZIndex} from 'devextreme/ui/overlay'

    baseZIndex(999)

    export default defineComponent({
        components: {
            DxPopup,
            DxToolbarItem,
            DxTextBox
        },
        props: {  
            'attachment': {},
            'allowUpload': Boolean,
            'allowDescription': Boolean,
            'allowDownload': Boolean,
            'allowFullScreen': Boolean,
            'allowEdit': Boolean,       
            'allowClear': Boolean,   
            'disableDrop': Boolean,     
            'uploadedIcon':String,
            'alternatives': {}, 
            'allowedTypes': {},   
            'title': {},                 
            height: { default: '200px' },     
            'clearOnUpload':Boolean,
            imgWidth: {},
            imgHeight: {},
            imgTrim: {},   
            imgOnePage: {},
            'minimal': {},
            'showUploadInFullScreen': {},
            'onUpload': {},            
            'showDeleteInFullScreen': {},
            'popUpOnClick': {},                
            'onClear': {},
            'buttons': { default: [] }
        },
        data() {
            return {
                name: null,
                url: null,
                id: null,
                invalid: false,
                hasImage: true,
                isAltImage: false,
                altDescription: null,
                over: false,
                counter: 0,
                msg: null,
                acceptTypeString: "",
                fullscreen: false,
                editing: false,
                stopWatcher: null,
                dbAttachment: null,
                popUpDeleteButton: {
                    icon: 'trash',
                    onClick: async () => {
                        await this.clear();
                        this.fullscreen = false;
                    }
                },
                popUpUploadButton: {
                    icon: 'upload',
                    onClick: () => {
                        this.openUpload();
                    }
                }
            };
        },
        created() {           
            if (this.allowedTypes) {
                if (typeof (this.allowedTypes) === "string") {
                    this.acceptTypeString = this.allowedTypes;
                } else if (Array.isArray(this.allowedTypes)) {
                    this.acceptTypeString = this.allowedTypes.join(", ");
                } else {
                    this.acceptTypeString = "";
                }
            }
            if(this.attachment){
                this.loadFromAttachment();
            }


        },
        methods: {
            loadMarkerStates() {                
                const markerStates = localStorage.getItem("markerStatesPersist");                
                return markerStates ? JSON.parse(markerStates) : {};                
            },
            saveMarkerStates(markerStates) {
                if (markerStates && Object.keys(markerStates).length > 0) {
                    localStorage.setItem("markerStatesPersist", JSON.stringify(markerStates));
                }
            },
            saveMarkerState(markerType, markerState) {
                let states = this.loadMarkerStates();
                states[markerType] = markerState
                this.saveMarkerStates(states)
            },
            async refresh() {
                if (this.stopWatcher)
                    this.stopWatcher();
                let att = this.getAttachment(this.attachment);
                attachmentApi.incrementVersion(att._id);
                this.loadFromAttachment();
            },
            async loadFromAlternatives() {
                if (this.hasImage)
                    return;
                if (!this.alternatives)
                    return;
                if (Array.isArray(this.alternatives)) {
                    for (let alt of this.alternatives) {
                        if (!this.hasImage) {
                            await this.loadAttachment(alt);
                        }
                    }
                }
                this.isAltImage = this.hasImage ? true : false;
                this.altDescription = this.hasImage ? this.dbAttachment.description : null;
                this.hasImage = false;
            },
            async getAttachment(attachment, noCache) {
                let att = null;
                if (typeof (attachment) === "string") {
                    att = await attachmentApi.get(attachment, noCache);
                }
                else if (typeof (attachment) === "object") {
                    if (attachment.assignment && attachment.assignmentType && attachment.name) {
                        att = await attachmentApi.getByAssignment(attachment.assignmentType, attachment.assignment, attachment.name, noCache);
                    }                
                }
                if (att) {
                    this.dbAttachment = att;                  
                    return att;
                }
            },
            async loadAttachment(attachment) {
                let att = await this.getAttachment(attachment);
                if (att) {
                    if (this.stopWatcher)
                        this.stopWatcher();
                    await this.setAttachment(att);
                    this.stopWatcher = attachmentApi.onVersionChange(att._id, async () => {
                        await this.setAttachment(att);
                    })
                    this.altDescription = null;
                } else {
                    this.hasImage = false;
                }     
                return att;
            },
            async loadFromAttachment() {
                await this.loadAttachment(this.attachment);  
                await this.loadFromAlternatives();
            },
            viewerClick() {
                if (this.popUpOnClick) {
                    this.fullscreen = true;
                }
            },
            dragover() {
            },
            dragenter(e) {
                if (this.disableDrop)
                    return;
                this.counter++;
                if (e.dataTransfer && e.dataTransfer.items) {
                    let types = Array.from(e.dataTransfer.items).map(n => n.type);

                    if (this.allowedTypes) {
                        if (typeof (this.allowedTypes) === "string" && types.every(t => t.toLowerCase().startsWith(this.allowedTypes.replace(/\*/g, '').toLowerCase()))) {
                            this.invalid = false;
                        } else if (Array.isArray(this.allowedTypes) && types.every(t => this.allowedTypes.map(at => at.toLowerCase()).includes(t.toLowerCase()))) {
                            this.invalid = false;
                        } else {
                            this.invalid = true;
                        }
                    } else {
                        this.invalid = false;
                    }
                    this.over = true;
                }
            },
            dragleave() {
                if (this.disableDrop)
                    return;
                this.counter--;
                if (this.counter < 0)
                    this.counter = 0;
                if (this.counter == 0) {
                    this.over = false;
                    this.invalid = false;
                } 
            },
            async saveFile(blob) {
                this.msg = "Datei wird hochgeladen..."
                let altDescription = null;
                if (this.isAltImage) {
                    altDescription = this.dbAttachment.description;
                }

                let att = await this.getAttachment(this.attachment);    
                if (!att) {
                    let saveData = structuredClone(this.attachment);
                    if (altDescription)
                        saveData.description = altDescription;
                    if (!saveData.name) {
                        saveData.name = guid() + ".png";
                    }
                    att = await attachmentApi.save(saveData)
                }
                await attachmentApi.saveBlob(att._id, blob);
                if (this.clearOnUpload) {
                    this.setClear();
                } else {
                    await this.loadAttachment(this.attachment);
                }                    
                if (this.onUpload)
                    this.onUpload(att);
            },
            async drop(e) {
                if (this.disableDrop)
                    return;
                if (this.invalid) {
                    notify("Mindestens eine der Dateien ist vom falschen Typ.", "error");
                    this.over = false;
                    this.invalid = false;
                    return;
                }
                if (e.dataTransfer && e.dataTransfer.files) {                    
                    let files = Array.from(e.dataTransfer.files); // SOMETHING WITH STREAMS?
                    // Todo: allow multiple?
                    if (files.length > 0) {
                        let file = files[0];
                        await this.saveFile(file);
                    }
                }
                this.over = false;
                this.invalid = false;
            },
            // TODO: is now async - change everywhere!
            async setAttachment(att) {
                if(!att || !att._id){
                    this.setClear();
                    return;
                }
                this.name = att.name;
                this._id = att._id;
                let blob = await attachmentApi.getThumbnailBlob(att._id, this.imgWidth, this.imgHeight, this.imgTrim, this.imgOnePage);
                if(!blob)
                    return;
                this.url = URL.createObjectURL(blob);
                this.hasImage = true;
                this.msg = null;
            },
            setClear(){
                this.name = null;
                this._id = null;
                this.url = null;
                this.hasImage = false;
                this.msg = null;
            },
            async clear() {
                if (this.hasImage) {
                    if (await confirm(`Soll das Bild gelöscht werden?`, `Bild löschen?`)) {
                        await attachmentApi.delete(this._id);
                        this.setClear();
                        this.loadFromAlternatives();
                        if (this.onClear)
                            this.onClear(this._id);
                    }
                } else {
                    notify("Kein bild?")
                }
            },
            openUpload() {
                if (this.$refs.fileInput) {
                    this.$refs.fileInput.value = null;
                    this.$refs.fileInput.click();
                }                
            },
            async download() {
                if (this.dbAttachment) {
                    attachmentApi.download(this.dbAttachment._id);                    
                } else {
                    let att = await this.getAttachment(this.attachment);
                    if (att)
                        attachmentApi.download(att._id);
                }
                
            },
            async fileInputChange() {
                if (this.$refs.fileInput && this.$refs.fileInput.files.length > 0) {
                    let file = this.$refs.fileInput.files[0];
                    await this.saveFile(file);
                }
            },
            async edit() {
                console.log("EDIT!");
                if (this.editing) {
                    this.editing = false;
                    return;
                }
                this.editing = true;
                if (this.$refs.editImg) {
                    this.$refs.editImg.width = this.$refs.editImg.parentElement.clientWidth;
                    var markerArea = new MarkerArea(this.$refs.editImg);
                    markerArea.settings.displayMode = 'popup';
                    console.log("markerArea",markerArea)
                    markerArea.addEventListener("render", async (event) => {
                        let blob = await fetch(event.dataUrl).then(res => res.blob());
                        await this.saveFile(blob);
                    });
                                        
                    markerArea.uiStyleSettings.zoomButtonVisible = true;
                    markerArea.uiStyleSettings.zoomOutButtonVisible = true;                                        

                    markerArea.availableMarkerTypes = ['ArrowMarker', 'FreehandMarker', 'TextMarker', 'HighlightMarker', 'CalloutMarker', 'FrameMarker', FilledRectangularBoxMarker, FilledEllipseMarker];


                    let currentMarker = null;
                    this.$nextTick(() => {
                        let button = markerArea.toolbar.buttons[0];
                        let saveStyleButton = document.createElement("div");
                        saveStyleButton.className = "__markerjs2__0_toolbar_button __markerjs2__0_toolbar_button_colors";
                        saveStyleButton.setAttribute("role", "button");
                        saveStyleButton.setAttribute("data-action", "save_style");
                        saveStyleButton.setAttribute("title", "Aktuelle Einstellung speichern");
                        saveStyleButton.setAttribute("aria-label", "Aktuelle Einstellung speichern");
                        saveStyleButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><g><rect fill="none" height="24" width="24"/></g><g><path d="M21,12.4V7l-4-4H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h7.4L21,12.4z M15,15c0,1.66-1.34,3-3,3s-3-1.34-3-3s1.34-3,3-3 S15,13.34,15,15z M6,6h9v4H6V6z M19.99,16.25l1.77,1.77L16.77,23H15v-1.77L19.99,16.25z M23.25,16.51l-0.85,0.85l-1.77-1.77 l0.85-0.85c0.2-0.2,0.51-0.2,0.71,0l1.06,1.06C23.45,16,23.45,16.32,23.25,16.51z"/></g></svg>`
                        button.parentElement.appendChild(saveStyleButton)
                        saveStyleButton.addEventListener("click", () => {
                            if (!currentMarker) {
                                notify({ message: "Kein Marker aktiv", width: "auto", type: "warning", position: "bottom right" });
                            } else {
                                let markerStyle = {
                                    fillColor: currentMarker.fillColor,
                                    strokeColor: currentMarker.strokeColor,
                                    opacity: currentMarker.opacity,
                                    strokeDasharray: currentMarker.strokeDasharray,
                                    strokeWidth: currentMarker.strokeWidth,
                                    arrowType: currentMarker.arrowType,
                                    bgColor: currentMarker.bgColor,
                                    tipPosition: currentMarker.tipPosition,
                                    textColor: currentMarker.textColor,
                                    fontFamily: currentMarker.fontFamily,
                                    fontSize: currentMarker.fontSize,
                                    color: currentMarker.color,
                                    padding: currentMarker.padding
                                };

                                for (var key in currentMarker) {
                                    if (!markerStyle[key])
                                        delete markerStyle[key];
                                }
                                for (let key in markerStyle) {
                                    if (typeof (markerStyle[key]) === "undefined")
                                        delete markerStyle[key];
                                }
                                notify({ message: "Gespeichert", width: "auto", type: "success", position: "bottom right" });
                                this.saveMarkerState(currentMarker.typeName, markerStyle);
                            }
                        })
                    })
                    markerArea.addEventListener("markercreating", async (e) => {
                        let marker = e.marker;                
                        currentMarker = e.marker;
                        let markerStates = this.loadMarkerStates();
                        if (markerStates[marker.typeName]) {
                            for (let key in markerStates[marker.typeName]) {
                                if (key in marker) {
                                    marker[key] = markerStates[marker.typeName][key]
                                }
                            }
                        }
                        /*
                        if(!marker.changeChecker)
                            marker.changeChecker = setInterval(() => {
                                if (!marker.isSelected)
                                    return;
                                let markerStyle = {
                                    fillColor: marker.fillColor,
                                    strokeColor: marker.strokeColor,
                                    opacity: marker.opacity,
                                    strokeDasharray: marker.strokeDasharray,
                                    strokeWidth:marker.strokeWidth,
                                    arrowType: marker.arrowType,
                                    bgColor:marker.bgColor,
                                    tipPosition: marker.tipPosition,
                                    textColor: marker.textColor,
                                    fontFamily: marker.fontFamily,
                                    fontSize: marker.fontSize,
                                    color: marker.color,
                                    padding: marker.padding
                                };

                            }, 50)*/
                    });
                    markerArea.addEventListener("close", async () => {
                        this.editing = false;
                    });
                    markerArea.show();
                }                    
            },
            async crop() {
                if (this.editing) {
                    this.editing = false;
                    return;
                }
                this.editing = true;
                this.$refs.editImg.width = this.$refs.editImg.parentElement.clientWidth;
                setTimeout(() => {
                    var markerArea = new CropArea(this.$refs.editImg);
                    markerArea.displayMode = 'popup';
                    markerArea.addRenderEventListener(async (dataUrl) => {
                        this.editing = false;
                        let blob = await fetch(dataUrl).then(res => res.blob());
                        await this.saveFile(blob);
                        this.editing = false;
                    });
                    markerArea.addCloseEventListener(async () => {
                        this.editing = false;
                    });
                    markerArea.show();
                }, 100)
            }

        }
    });
</script>

<style>
    .dx-popup-content{
        position: relative;
    }
    .image-viewer {
        border: 1px solid var(--color-background-light);
        background-color: var(--color-background-light);
        padding: 5px 10px;
        border-radius: var(--default-border-radius);
        position: relative;
    }
        .image-viewer-image {
            position: absolute;
            z-index: 0;
            inset: 0;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin: 10px 0;
        }
            .image-viewer-alt {
                outline: 3px dashed var(--color-background-lighter);
            }
            .image-viewer-image.image-viewer-full {
                width: 100%;
                height: 100%;
                margin: 0;
            }
            .image-viewer-over {
                box-shadow: inset 0 0 4px var(--color-success);
            }
            .image-viewer-invalid {
                box-shadow: inset 0 0 4px var(--color-error);
            }
    .image-viewer-buttons {
        z-index: 2;
        position: absolute;
        top: 5px;
        right: 5px;
    }
    .image-viewer-button {
        transition: all 0.3s ease-in-out;
        padding: 6px;
        font-size: 1.2rem;
        margin-left: 7px;
        border-radius: 50%;
        background: var(--color-background);
        color: var(--color-text);
    }
        .image-viewer-button:hover {
            background: var(--color-background-lighter);
        }
    .image-viewer-info {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        font-size: 20px;
        width: 100%;
        padding: 10px;
        background: var(--color-background);
    }
    .image-viewer-info-upload {
        z-index: 1;
        opacity: 0.7;
        filter: drop-shadow(0px 0px 4px black);
        position: absolute;
        left: 0;
        bottom: 50%;
        margin-bottom: -2rem;
        text-align: center;
        font-size: 4rem;
        width: 100%;
    }
    .image-viewer-desc{
        position: absolute;
        opacity: .8;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .image-viewer-desc-alt{
        padding: 8px 12px;
        background-color: var(--color-background);
        color: var(--color-text);
    }
    .image-viewer-minimal.image-viewer {
        padding: 0px;
        background-color: transparent;
        border: none;
    }

        .image-viewer-minimal.image-viewer .image-viewer-image {
            margin: 0px;
        }
    .image-viewer-minimal .image-viewer-info {
        display: none;
    }
    .image-viewer-minimal .image-viewer-info-upload {
        top: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        font-size: 2rem;
        word-wrap: break-word;
        white-space: break-spaces;
    }
</style>
