<template>
    <div class="area-content-body mobile-root">
        <div class="mobile-head" :class="{dirty:$root.isDirty}">
            <span class="head-toolbar-button-compact no-margin" @click="refresh">refresh</span>
            <span class="mobile-head-title">
                Projekte
            </span>
            <span v-if="showInstall" class="head-toolbar-button-compact" @click="install">install_mobile</span>
            <span class="head-toolbar-button-compact" @click="toggleOnline">{{isOffline?'cloud_off':'cloud'}}</span>
            <span class="head-toolbar-button-compact no-margin" @click="logout">logout</span>
        </div>
        <DxDataGrid v-if="projects && projects.length > 0"
                    class="mobile-projects-grid"
                    :focused-row-enabled="true"
                    :show-column-headers="false"
                    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
                    key-expr="_id"
                    ref="grid"
                    :data-source="projects"
                    @initialized="gridInit"
                    @rowClick="changeProject">
            <DxColumn data-field="number" caption="Projekt-Nr." :sort-index="0" sort-order="asc" width="150" />
            <DxColumn data-field="name" caption="Projekt-Bezeichnung" />

            <DxColumn cell-template="buttonTemplate" width="51" />
            <template #buttonTemplate="{ data }">
                <span class="head-toolbar-button-compact no-margin" :class="{'mobile-button-filled':data.data.protocolInProgress}" @click.prevent.stop="startProtocol(data)">photo_camera</span>
            </template>
            <DxSearchPanel :visible="true" :width="maxWidth" />
        </DxDataGrid>
        <div v-else-if="loading">
            Daten werden geladen!
        </div>
        <div v-else-if="noUser">
            Ihr Benutzer ist nicht korrekt angelegt!
        </div>
        <div v-else>
            Ihnen wurden keine aktiven Projekte zugewiesen!
        </div>
        <DxPopup v-model:visible="showBlockingProgress"
                 :fullscreen="true"
                 height="100px"
                 :drag-enabled="false"
                 :hide-on-outside-click="false"
                 :show-close-button="false"
                 :show-title="false">
            <div class="mobile-progess-info">{{progressInfo}}</div>
            <div class="mobile-progress" :style="{width:progress+'%'}">
            </div>
        </DxPopup>
    </div>
    

</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import DxDataGrid, {
        DxColumn,
        DxSearchPanel
    } from 'devextreme-vue/data-grid';
    import DxPopup from 'devextreme-vue/popup';
    import projectApi from '../services/ProjectApi';
    import { askForImage } from '../services/Util';
    import attachmentApi from '../services/AttachmentApi';
    import offlineService, { offlineProjectApi } from '../services/OfflineService';
    import notify from 'devextreme/ui/notify';
    import { confirm } from 'devextreme/ui/dialog';
    import projectProtocolApi from '../services/ProjectProtocolApi';
    import userApi from '../services/UserApi';
    import { authService } from '../services/AuthService';
    import { afterProcessingAsync } from '../services/Processor';

    export default defineComponent({
        data() {
            return {
                loading: false,
                projects: null,
                noUser:false,
                showInstall: false,
                installEvent: null,
                progress: 0,
                showBlockingProgress: false,
                progressInfo: null,
                isOffline: offlineService.isOffline,
                popupImage:null,
                showPopupImage: false,
                maxWidth: 160
            };
        },
        async created() {
            this.$root.hideMenu = true;
            this.$root.hideHead = true;
            this.$root.hideLogo = true;
            this.maxWidth = window.innerWidth-40;
            this.fetchData();
            this.checkInstalled();
        },
        watch:{
            showPopupImage(){
                if(!this.showPopupImage){
                    this.popupImage = null
                }
            }
        },
        components: {
            DxDataGrid,
            DxColumn,
            DxSearchPanel,
            DxPopup
        },       
        methods: {
            async gridInit(){
            },
            async refresh() {
                location.reload();
            },
            async toggleOnline(){
                try{
                    if (offlineService.checkIsOffline) {
                        if (!navigator.onLine) {
                            notify("Ohne Verbindung zum Internet können Sie nicht online gehen.")
                            return;
                        }
                        this.showBlockingProgress = true;
                        this.progressInfo = "Lade Protokolldaten hoch";
                        this.progress = 0;
                        offlineService.isOffline.value = false;
                        setTimeout(() => {
                            localStorage["isOffline"] = "true"
                        }, 10)                        
                        await offlineService.uploadData((p,info) => {
                            this.progress = Math.min(p * 100, 100);
                            if(info)
                                this.progressInfo = info;
                        });
                        this.progressInfo = null;
                        console.log("ISOFFLINE", localStorage["isOffline"])
                        this.showBlockingProgress = false;
                        delete localStorage["isOffline"]
                    } else {
                        if (await confirm("Möchten Sie in den Offline-Betrieb wechseln?", "Offline gehen?")) {
                            this.showBlockingProgress = true;
                            this.progressInfo = "Lade Daten für Offlinenutzung herunter";
                            await offlineService.loadAllData((p,info) => {
                                this.progress = Math.min(p * 100,100);
                                if(info)
                                    this.progressInfo = info;
                            }, this.$root.currentSub)
                            this.progressInfo = null;
                            this.showBlockingProgress = false;
                            offlineService.isOffline.value = true;                                             
                        }
                    }                
                }catch (ex){
                    notify("[ERR] "+ ex,"error");
                    console.error(ex);
                }
            },
            async install(){
                await this.installEvent.prompt()
            },
            async checkInstalled() {
                window.addEventListener('beforeinstallprompt', (e) => {
                    this.installEvent = e;
                    this.showInstall = true;
                });
            },
            async fetchData() {
                this.loading = true;
                let user = await userApi.getBySubject(this.$root.currentSub);
                if (!user) {
                    this.noUser = true;
                    return;
                }
                this.noUser = false;
                let userId = user._id;
                if (offlineService.checkIsOffline) {
                    this.projects = await offlineProjectApi.getForUser(userId);
                    console.log("OFFLINE", offlineProjectApi,this.projects,userId)
                } else {
                    this.projects = structuredClone((await projectApi.search(`Archived:0 AND Status:Active AND (_CoordinatorId:${userId} OR _SubstituteCoordinatorId:${userId})`, 300)).data);
                    console.log("ONLINE", this.projects)
                }
                this.loading = false;
            },
            async changeProject(row, force) {
                /*if (!row.data.protocolInProgress) {
                    notify("Es muss ein neues Protokoll gestartet werden!", "warning")
                    return;
                }*/
                if (!force) {
                    let currentProtocol = await projectProtocolApi.get(row.key);
                    console.log("changeProject", currentProtocol)
                    if (!currentProtocol || currentProtocol.currentVersionIsSent || !currentProtocol.creationDate) {
                        console.error()
                        notify("Es muss ein neues Protokoll gestartet werden!", "warning")
                        return;
                    }
                }                
                this.$router.push("/Mobile/" + row.key);
            },
            async startProtocol(data) {
                console.log("START PROTOCOLL:", data);
                
                let id = data.key;
                let image = (await askForImage().catch(e => e != "cancel" ? notify("[ERR] " + e, "error") : null));
                let currentProtocol = await projectProtocolApi.get(id).catch(e =>notify("[ERR PROJ] " + e, "error"));
                if (currentProtocol && currentProtocol.currentVersion > 0 && !currentProtocol.currentVersionIsSent && currentProtocol.creationDate) {
                    if (await confirm("Das aktuelle Protokoll wurde noch nicht Versand. Soll das Bild für das Protokoll ersetzt werden?", "Protokoll noch nicht abgeschlossen - Bild ersetzen?")) {
                        let att = await attachmentApi.getByAssignment('project', id, 'ProjektUebersicht.png')
                        if (!att) {
                            att = await attachmentApi.save({ assignmentType: 'project', assignment: id, name: 'ProjektUebersicht.png' });
                        }
                        if (att) {                            
                            await attachmentApi.save(att);
                            await attachmentApi.saveBlob(att._id, image);
                        }
                        this.changeProject(data, true);
                    }
                    return;
                }
                if (!image) {
                    notify("Kein Bild ausgewählt!", "warning")
                    return;
                }
                let att = null;
                try{
                    att = await attachmentApi.getByAssignment('project', id, 'ProjektUebersicht.png')
                    if (!att) {
                        att = await attachmentApi.save({ assignmentType: 'project', assignment: id, name: 'ProjektUebersicht.png' });
                    }
                    if (att) {
                        await attachmentApi.save(att);
                        await attachmentApi.saveBlob(att._id, image);
                    }
                    if (!currentProtocol) {
                        currentProtocol = await projectProtocolApi.save({
                            projectId: id,
                            deficiencies: [],
                            currentVersionIsSent: false,
                            currentVersion: 1,
                            creationDate: new Date().toJSON()
                        });
                    } else {
                        currentProtocol.currentVersionIsSent = false;
                        currentProtocol.creationDate = new Date().toJSON()
                        await afterProcessingAsync()
                    }

                   
                    data.data.protocolInProgress = true;
                    await projectApi.updateProtocolStateWhenOffline(data.data);

                    this.changeProject(data, true);
                }catch (ex){
                    notify("[ERR] "+ ex,"error");
                    console.error(ex);
                }                
            },
            logout() {
                authService.logout();
            },
            async getImage(data) {
                let id = data.key;
                let att = await attachmentApi.getByAssignment('project', id,'ProjektUebersicht.png')

                if(att){
                    this.showPopupImage = true;
                    this.popupImage = att._id;
                    return;                
                }
                let image = await askForImage().catch(e => console.log("[ERR]", e))
                if (image) {
                    if (!att) {
                        att = await attachmentApi.save({ assignmentType: 'project', assignment: id, name: 'ProjektUebersicht.png' });
                    }
                    if(att)
                        await attachmentApi.saveBlob(att._id, image);
                }                
            }
        }
    });
</script>


<style>
    .mobile-head {
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        margin-bottom: 5px;
    }
    .mobile-head {
        position: relative;
        transition: all 0.3s ease-in-out;
    }

        .mobile-head.dirty {
            padding: 5px 0;
        }

        .mobile-head::after {
            transition: all 0.3s ease-in-out;
            content: '';
            position: absolute;
            opacity: 0;
            height: 0;
            bottom: 50%;
            left: 0;
            background: linear-gradient(45deg, #ffff0022 25%, #333333 25%, #333333 50%, #ffff0022 50%, #ffff0022 75%, #333333 75%, #333333 100%);
            background-size: 56.57px 56.57px;
            right: 0;
        }

        .mobile-head.dirty::after {
            opacity: 1;
            height: 100%;
            bottom:0;
        }

    .mobile-head-title {
        font-size: 1.5rem;
        text-align: center;
        padding: 5px 15px;
    }
    .mobile-root {
        display: grid;
        grid-gap: 5px;
        grid-template-rows: max-content minmax(0,1fr) min-content;
    }
    .mobile-button-filled {
        background-color: var(--color-success) !important;
    }
    .mobile-progress {
        height: 20px;
        background-color: var(--color-success);
        transition: width 0.2s ease;
    }
    .mobile-projects-grid {
        padding: 10px;
        background: var(--color-dx-dark);
        border-radius: var(--default-border-radius);
    }

</style>

