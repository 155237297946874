<template>
    <div class="area-content-body admin-list-contain">
        <DxDataGrid class="admin-lists-grid" :focused-row-enabled="true"
                    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
                    key-expr="_id"
                    v-if="lists && lists.length > 0"
                    :data-source="lists"
                    v-model:selectedRowKeys="list"
                    @selection-changed="selectionChanged">
            <DxColumn data-field="name" caption="Liste" />

            <DxSelection mode="single" />
        </DxDataGrid>
        <div class="no-data-message" v-else>
            Listen werden geladen...
        </div>
        <router-view></router-view>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import DxDataGrid,{        
        DxColumn,
        DxSelection
    } from 'devextreme-vue/data-grid';
    import listApi from '../services/ListApi';

    export default defineComponent({
        components: {
            DxDataGrid,
            DxColumn,
            DxSelection
        },
        data() {
            return {
                lists:[],
                list:null
            };
        },
        watch:{
            list() {
                if(this.list)
                    this.$router.push("/Admin/Lists/"+this.list)
            }
        },
        async created() {
            this.itemsGrid = null
            await this.fetchData();
        },
        methods: {
            async clearOne(listId) {
                await listApi.delete(listId);
                this.items = await listApi.getListItems(listId);
                for (let item of this.items) {
                    await listApi.deleteItem(item._id);
                }
            },
            async fetchData(){
                this.lists = (await listApi.getAll()).filter(l => !l.parentListItemId && !l.internalName.startsWith("sys_"));
                if (this.lists.length == 0) {
                    //
                }else{
                    // await this.clear();
                    this.list = this.$route.params?.id || this.lists[0]._id
                }
            },
            async selectionChanged(e){
                this.list = e.selectedRowKeys[0];
            }
        },
    });
</script>

<style>
    .admin-list-contain{
        display:grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 10px;
    }
    .admin-lists-grid .dx-datagrid {
        padding: 10px;
        background-color: #2a2a2a;
    }
</style>
