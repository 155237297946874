<template>
    <div class="area-content-head" :class="{'head-overview':$route.params.id}">
        <div v-if="!$route.params.id" class="head-title">Projekte</div>
        <div v-if="$route.params.id" class="head-toolbar-large">
            <!-- Toolbar with id -->
            <router-link :to="'/Projects/'+$route.params.id+'/'+item.context" v-for="item in items" v-bind:key="item.context" class="head-toolbar-item" :class="{'disabled':!item.enabled,'router-link-active':$route.path.match(new RegExp('/'+item.context+'(/|$)','i'))}">
                <div class="head-toolbar-item-icon material-symbols-outlined">{{item.icon}}</div>
                <div v-if="!item.subLabel" class="head-toolbar-item-label">{{item.label}}</div>
                <div v-if="item.subLabel" class="head-toolbar-item-label-main">{{item.label}}</div>
                <div v-if="item.subLabel" class="head-toolbar-item-label-sub">{{item.subLabel}}</div>
            </router-link>
        </div>
        <div v-else class="head-toolbar">
            <!-- Toolbar without id -->
            <div class="head-toolbar-button-compact material-symbols-outlined" @click="addProject" title="Projekt hinzufügen">add</div>
            <div class="head-toolbar-button-compact material-symbols-outlined" @click="setArchived=true" title="Projekt archivieren">{{showArchived?'unarchive':'archive'}}</div>
            <div class="head-toolbar-button-compact material-symbols-outlined" :class="{'border-color-warn':showArchived}" @click="showArchived=!showArchived" title="Archiv anzeigen">inventory_2</div>
        </div>
        <div v-if="$route.params.id" class="head-placeholder"></div>
        <head-component />
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import headComponent from '../components/head-component.vue';        

    const items = [
        {
            label: "Info",
            icon: "info",
            context: "",
            enabled: true,
        },
        {
            label: "Vorankündigung",
            icon: "description",
            stageName: 'Notice',
            context: "notice",
            enabled: true,
        },
        {
            label: "Planungsphase",
            subLabel: "SiGe-Plan",
            stageName: 'Planning',
            icon: "space_dashboard",
            context: "sige-plan-planning",
            enabled: true
        },
        {
            label: "Planungsphase",
            subLabel: "Spätere Arbeiten",
            stageName: 'Planning',
            icon: "event_available",
            context: "later-work-planning",
            enabled: true
        },
        {
            label: "SiGe-Plan",
            icon: "assignment_late",
            stageName: 'SiGePlan',
            context: "sige-plan",
            enabled: true
        },
        {
            label: "Baustellen",
            subLabel: "Ordnung",
            icon: "gavel",
            stageName: 'Inspection',
            context: "order",
            enabled: true
        },
        {
            label: "Protokolle",
            icon: "content_paste",
            stageName: 'Inspection',
            context: "protocol",
            enabled: true
        },
        {
            label: "Finale Unterlage",
            icon: "history_edu",
            stageName: 'SubsequentWorkDocs',
            context: "final",
            enabled: true
        }
    ]

    export default defineComponent({
        components:{
            headComponent
        },
        data() {
            return {
                items,
                showArchived:false,
                setArchived:false
            };
        },
        watch:{
            "$root.project.stages"() {
                if (this.$root?.project?.stages) {
                    this.items.forEach(i => {
                        i.enabled = !i.stageName || this.$root.project.stages.includes(i.stageName);
                    })
                }
            }
        },
        created() {
           
        },
        methods: {
            changeTab(id, tab) {
                this.$router.push("/Projects/" + id + "/" + tab);
            },
            async addProject() {
                this.$router.push("/Projects/new");
            },
        }
    });
</script>

<style>


</style>
