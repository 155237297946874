<template>
    <DxLookup v-if="!view" :data-source="dataSource" :display-expr="displayExpr" value-expr="_id" v-model:value="editableValue" :showClearButton="showClearButton" :showCancelButton="false" :itemTemplate="itemTemplate" :fieldTemplate="fieldTemplate">
        <DxDropDownOptions :show-title="false" :hide-on-outside-click="true" :min-width="minWidth" />
        <template #field-template="{ data }">
            <div>{{ displayExpr(data) }}</div>
        </template>
    </DxLookup>
    <div v-else>{{text}}</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import {
        DxLookup,
        DxDropDownOptions
    } from 'devextreme-vue/lookup';
    
    import organizationApi from "../services/OrganizationApi";
    import DataSource from 'devextreme/data/data_source';
    import { luceneConverter } from '../services/Util';
    let cache = { }
    export default defineComponent({
        components: {
            DxLookup,
            DxDropDownOptions
        },
        props: ['contactValue', 'organizationValue', 'showOrganization', 'onContactValueChanged', 'onOrganizationValueChanged', 'view', 'display', 'organizationFilter', 'showClearButton', 'onCreated','minWidth'],
        data() {
            return {
                editableValue: null,
                text: null
            };
        },
        watch:{
            async editableValue() {
                let contact = await organizationApi.getContact(this.editableValue);
                if (contact) {
                    if (this.organizationValue != contact.organizationId) {
                        this.$emit('update:organizationValue', contact.organizationId);
                        if (this.onOrganizationValueChanged)
                            this.onOrganizationValueChanged(contact.organizationId);
                    }
                    if (this.contactValue != contact._id) {
                        this.$emit('update:contactValue', contact._id);
                        if (this.onContactValueChanged)
                            this.onContactValueChanged(contact._id);
                    }
                } else {
                    let organization = await organizationApi.get(this.editableValue);
                    if (organization) {
                        if (this.organizationValue != organization._id) {
                            this.$emit('update:organizationValue', organization._id);
                            if (this.onOrganizationValueChanged)
                                this.onOrganizationValueChanged(organization._id);
                        }
                        if (this.contactValue != null) {
                            this.$emit('update:contactValue', null);
                            if (this.onContactValueChanged)
                                this.onContactValueChanged(null); 
                        }                        
                    }else{
                        console.error("organization",this.editableValue)
                    }
                }
                let result = await organizationApi.search("_id:" + this.editableValue, 1, 0, "Name,FirstName,LastName,OrganizationId_Name");
                let item = result.totalOrganizationHits ? result.organizations[0] : result.contacts[0];
                this.text = this.displayExpr(item);
                cache[this.editableValue] = item;
            }
        },
        async created() {
            if (this.onCreated)
                this.onCreated()
            this.editableValue = this.contactValue || this.organizationValue;
            this.dataSource = new DataSource({
                load: async (loadOptions) => {
                    if (loadOptions.filter) {
                        let query = luceneConverter.toLucene(loadOptions.filter);
                        let result = await organizationApi.search(query, loadOptions.take, loadOptions.skip, "Name,FirstName,LastName,OrganizationId_Name")
                        return {
                            data: result.organizations.concat(result.contacts), totalCount: result.totalHits
                        };
                    } else if (loadOptions.searchValue) {
                        let result = await organizationApi.search("**:" + loadOptions.searchValue, loadOptions.take, loadOptions.skip, "Name,FirstName,LastName,OrganizationId_Name");
                        return {
                            data: result.organizations.concat(result.contacts), totalCount: result.totalHits
                        }
                    } else {
                        let result = await organizationApi.search("*:*", loadOptions.take, loadOptions.skip, "Name,FirstName,LastName,OrganizationId_Name");
                        return {
                            data: result.organizations.concat(result.contacts), totalCount: result.totalHits
                        }
                    }
                },
                byKey: async (key) => {
                    if (key in cache)
                        return cache[key]
                    let result = await organizationApi.search("_id:" + key, 1, 0, "Name,FirstName,LastName,OrganizationId_Name");
                    return result.totalOrganizationHits ? result.organizations[0] : result.contacts[0];
                },
                key: "_id"
            });
        },
        methods: {
            itemTemplate(r) {
                return `<div class='contact-organization-item' title="${this.displayExpr(r)}"><span class='material-symbols-outlined'>${r.OrganizationId_Name ? 'person' : 'business'}</span><span class="item-text">${this.displayExpr(r)}</span></div>`;
            },
            fieldTemplate(r) {
                return `<div class='field-item' title="${this.displayExpr(r)}"><span class="item-text">${this.displayExpr(r)}</span></div>`
            },
            displayExpr(r) {
                if (this.display)
                    return this.display(r);
                if (!r)
                    return "";
                if (!r.OrganizationId_Name)
                    return r.Name;
                if (this.showOrganization)
                    return `${r.FirstName || ""} ${r.LastName || ""} (${r.OrganizationId_Name || ""})`
                return (r.FirstName || "") + ' ' + (r.LastName || "");
            }
        }
    });
</script>

<style>
    .contact-organization-item{
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 10px;
        overflow: hidden;
    }

        .contact-organization-item .item-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 8px;
            flex-grow: 1;
        }
    
        .field-item {
        overflow: hidden;
        text-overflow: ellipsis;
    }
 
</style>
