<template>
    <div class="main-data-organization">
        <div class="main-data-organization-o">
            <DxForm class="main-data-organization-form" :form-data="organization" :show-validation-summary="true" label-mode="outside" label-location="top" :col-count="8">
                <DxSimpleItem data-field="name" :label="{text:'Firma'}" :col-span="3">
                </DxSimpleItem>
                <DxSimpleItem data-field="streetAndNr" :label="{text:'Straße / Nr.'}" :col-span="2">
                </DxSimpleItem>
                <DxSimpleItem data-field="zipCode" :label="{text:'PLZ'}" :col-span="1">
                </DxSimpleItem>
                <DxSimpleItem data-field="city" :label="{text:'Ort'}" :col-span="2">
                </DxSimpleItem>
                <DxSimpleItem data-field="eMail" :label="{text:'E-Mail'}" :col-span="3">
                </DxSimpleItem>
            </DxForm>
            <DxDataGrid class="main-data-organization-grid"
                        :focused-row-enabled="true"
                        :focused-row-key="focusedRowKey"
                        :auto-navigate-to-focused-row="true"
                        key-expr="_id"
                        :data-source="contacts"
                        @saving="saving"
                        :pager="{showInfo:true}"
                        @toolbar-preparing="$root.addGridTitle($event,'Ansprechpartner')">
                <DxColumn data-field="salutationId" edit-cell-template="salutationTemplate" cell-template="salutationTemplateView" caption="Anrede" />
                <template #salutationTemplate="{ data: cellInfo }">
                    <ListLookup list="contact_salutation" :value="cellInfo.value" :on-value-changed="cellInfo.setValue"></ListLookup>
                </template>
                <template #salutationTemplateView="{ data: cellInfo }">
                    <ListLookup list="contact_salutation" :value="cellInfo.value" :on-value-changed="cellInfo.setValue" :view="true"></ListLookup>
                </template>
                <DxColumn data-field="_id" :visible="false" :allowSearch="false"></DxColumn>
                <DxColumn data-field="firstName" caption="Vorname" />
                <DxColumn data-field="lastName" caption="Nachname" />
                <!--<DxColumn data-field="function" caption="Funktion" />-->
                <DxColumn data-field="eMail" caption="E-Mail" />
                <DxColumn data-field="phoneNumber" caption="Telefon" :validationRules="[{type: 'pattern',pattern:'^\\+?[0-9\\- ]*$'}]" />
                <DxColumn data-field="mobileNumber" caption="Mobil" :validationRules="[{type: 'pattern',pattern:'^\\+?[0-9\\- ]*$'}]" />
                <DxEditing :allow-updating="true"
                           :allow-deleting="true"
                           :allow-adding="true"
                           mode="batch"
                           v-model:changes="changes"
                           new-row-position="last"
                           :useIcons="true" />

                <DxSearchPanel :visible="true" />
            </DxDataGrid>
        </div>
        <DxDataGrid class="main-data-projects-grid"
                    key-expr="_id"
                    :data-source="involvedProjects"
                    @row-click="rowClick"
                    @toolbar-preparing="$root.addGridTitle($event,'Projektbeteiligung')">
            <DxColumn data-field="_id" :visible="false" :allowSearch="false"></DxColumn>
            <DxColumn data-field="number" caption="Projektnummer" />
        </DxDataGrid>
    </div>

</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import DxDataGrid, {
        DxColumn,
        DxEditing,
        DxSearchPanel
    } from 'devextreme-vue/data-grid';
    import DxForm, {
        DxSimpleItem
    } from 'devextreme-vue/form';
    import ListLookup from '../components/list-lookup.vue';
    import organizationApi from '../services/OrganizationApi';
import { addProcess } from '../services/Processor';


    export default defineComponent({
        components: {
            DxForm,
            DxSimpleItem,

            DxDataGrid,
            DxColumn,
            DxEditing,
            ListLookup,
            DxSearchPanel
        },
        data() {
            return {
                contacts: [],
                involvedProjects: [],
                formData: null,
                organization: null,
                process: null,
                changes: [],
                focusedRowKey: null
            };
        },
        watch: {
            '$route.params.id'() {
                this.fetchData();
            },
            '$route.params.subId'() {
                this.focusedRowKey = this.$route.params.subId;
            },
            changes(){
                if(this.process)
                    this.process();
                if(this.changes.length>0){
                    this.process = addProcess("Changing DataGrid");
                }
            }
        },
        async created() {
            await this.fetchData();
        },
        methods: {
            async fetchData() {
                if (!this.$route.params?.id) {
                    return;
                }
                // Parallel:
                [this.contacts, this.organization, this.involvedProjects] = await Promise.all([organizationApi.getOrganizationContacts(this.$route.params.id), organizationApi.get(this.$route.params.id), organizationApi.getProjectsInvolved(this.$route.params.id)])
                if (this.$route.params.subId) {
                    this.focusedRowKey = this.$route.params.subId;
                }
            },
            async rowClick(e) {
                window.open(`/Projects/${e.key}`, '_blank')
            },
            async saving(e) {
                for (let change of e.changes) {
                    let item = structuredClone(this.contacts.find(t => t._id === change.key));
                    if (change.type === "update") {
                        Object.assign(item,change.data)
                        await organizationApi.saveContact(item);
                    }
                    else if (change.type === "insert") {
                        item = change.data;
                        item.organizationId = this.$route.params.id;
                        await organizationApi.saveContact(item);
                    }
                    else if (change.type === "remove") {
                        await organizationApi.deleteContact(change.key)
                    }
                    else {
                        console.error("DONT KNOW", change.type)
                    }
                }
                if(this.process)
                    this.process();
            },
        },
    });
</script>

<style>
    .main-data-organization {
        display: grid;
        grid-template-columns: minmax(0,3fr) 1fr;
        grid-template-rows: minmax(0,1fr);
        grid-gap: 10px;
    }
    .main-data-organization-o {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: max-content minmax(0,1fr) minmax(0,max-content);
        grid-gap: 10px;
    }
    .main-data-organization-grid .dx-datagrid {
        padding: 10px;
        background-color: #2a2a2a;
    }
    .main-data-projects-grid .dx-datagrid {
        padding: 10px;
        background-color: #2a2a2a;
    }
</style>
