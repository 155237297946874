<template>
    <div>
        <span class="head-toolbar-button-compact" @click="popupVisible=!popupVisible" title="Projektbeteiligung öffnen">groups</span>
        <dx-popup v-model:visible="popupVisible" width="80%" height="80%" :hide-on-outside-click="true" :show-close-button="true" title="Projektbeteiligte" @showing="fetchData">
            <template #content>
                <dx-data-grid ref="dataGrid"
                              class="project-involved-grid"
                              :focused-row-enabled="true"
                              key-expr="id"
                              height="100%"
                              :data-source="editableValue"
                              :scrolling="{mode:'standard',preloadEnabled:true}"
                              :paging="{enabled:false}"
                              @init-new-row="addId"
                              @cell-prepared="onCellPrepared"
                              @saving="saving"
                              :row-dragging="{
                allowReordering: true,
                showDragIcons: true,
                onReorder: handleRowReorder
              }">
                    <dx-editing :allow-updating="true"
                                :allow-deleting="true"
                                :allow-adding="true"
                                mode="batch"
                                new-row-position="last"
                                :useIcons="true" />


                    <dx-column data-field="listItemIds" edit-cell-template="listTagTemplate" cell-template="listTagTemplateView" caption="Zuordnung" :validation-rules="[{type: 'async',validationCallback: checkUnique},{type: 'async',validationCallback: checkRequiresTrade,message:'Bitte das Gewerk der ausführenden Firma auswählen.'}]" />
                    <template #listTagTemplate="{ data: cellInfo }">
                        <list-lookup v-if="cellInfo" list="project_assignments" :tags="true" :value="cellInfo.value" :on-value-changed="cellInfo.setValue"></list-lookup>
                    </template>
                    <template #listTagTemplateView="{ data: cellInfo }">
                        <list-lookup v-if="cellInfo" list="project_assignments" :tags="true" :value="cellInfo.value" :view="true"></list-lookup>
                    </template>

                    <dx-column data-field="tradeId" edit-cell-template="tradeTemplate" cell-template="tradeTemplateView" caption="Gewerk"></dx-column>
                    <template #tradeTemplate="{ data: cellInfo }">
                        <ListLookup list="project_trades" :value="cellInfo.value" :on-value-changed="cellInfo.setValue" :allowEmpty="true"></ListLookup>
                    </template>
                    <template #tradeTemplateView="{ data:cellInfo }">
                        <ListLookup list="project_trades" :value="cellInfo.value" :on-value-changed="cellInfo.setValue" :allowEmpty="true" :view="true"></ListLookup>
                    </template>

                    <dx-column data-field="organizationId" edit-cell-template="organizationTemplate" cell-template="organizationTemplateView" caption="Firma" :validation-rules="[{type: 'async',validationCallback: checkOrganizationUnique}]" />
                    <template #organizationTemplate="{ data: cellInfo }">
                        <organization-lookup v-if="cellInfo" :value="cellInfo.value" min-width="400px" :on-value-changed="async (v) => {
                                        if (v == cellInfo.value) return;
                                        cellInfo.setValue(v);
                                        clearContact(cellInfo, v);
                        }" :showClearButton="true"></organization-lookup>
                    </template>

                    <template #organizationTemplateView="{ data: cellInfo }">
                        <organization-lookup v-if="cellInfo" :value="cellInfo.value" :view="true"></organization-lookup>
                    </template>

                    <dx-column data-field="contactId" edit-cell-template="contactTemplate" cell-template="contactTemplateView" caption="Person"
                               :validation-rules="[{type: 'async',validationCallback: checkContactUnique}]" />
                    <template #contactTemplate="{ data: cellInfo }">
                        <contact-lookup v-if="cellInfo" :value="cellInfo.value" min-width="400px" :on-value-changed="(v)=>{cellInfo.setValue(v);updateOrganization(cellInfo,v)}" :organizationFilter="cellInfo.data.organizationId" :showClearButton="true"></contact-lookup>
                    </template>
                    <template #contactTemplateView="{ data: cellInfo }">
                        <contact-lookup v-if="cellInfo" :value="cellInfo.value" :view="true"></contact-lookup>
                    </template>

                    <dx-column cell-template="contactTemplateEmail" caption="E-Mail" :allow-editing="false"
                               :validation-rules="[{type: 'custom', message: 'Ungültige E-Mail', validationCallback: checkEMail}]" />
                    <template #contactTemplateEmail="{ data: cellInfo }">
                        <contact-lookup v-if="cellInfo && cellInfo.data.contactId" :value="cellInfo.data.contactId" :display="(d)=>d&&d.EMail" :view="true"></contact-lookup>
                        <organization-lookup v-else-if="cellInfo && cellInfo.data.organizationId" :value="cellInfo.data.organizationId" :display="(d)=>d&&d.EMail" :view="true"></organization-lookup>
                    </template>

                    <dx-column cell-template="contactTemplatePhone" caption="Telefon" :allow-editing="false" />
                    <template #contactTemplatePhone="{ data: cellInfo }">
                        <div v-if="cellInfo && cellInfo.data.contactId" :value="cellInfo.data.contactId">
                            <contact-lookup :value="cellInfo.data.contactId" :display="(d)=>{console.log('DISPLAY::',d);return d&&d.PhoneNumber}" :view="true"></contact-lookup>
                        </div>
                    </template>

                    <dx-column cell-template="organizationTemplateStreet" caption="Adresse" :allow-editing="false" />
                    <template #organizationTemplateStreet="{ data: cellInfo }">
                        <div v-if="cellInfo && cellInfo.data.organizationId" :value="cellInfo.data.organizationId">
                            <organization-lookup :value="cellInfo.data.organizationId" :display="(d)=>d&&d.StreetAndNr" :view="true"></organization-lookup>
                        </div>
                    </template>

                    <dx-column cell-template="organizationTemplateZip" caption="PLZ" :allow-editing="false" />
                    <template #organizationTemplateZip="{ data: cellInfo }">
                        <div v-if="cellInfo && cellInfo.data.organizationId" :value="cellInfo.data.organizationId">
                            <organization-lookup :value="cellInfo.data.organizationId" :display="(d)=>d&&d.ZipCode" :view="true"></organization-lookup>
                        </div>
                    </template>

                    <dx-column cell-template="organizationTemplateCity" caption="Stadt" :allow-editing="false" />
                    <template #organizationTemplateCity="{ data: cellInfo }">
                        <div v-if="cellInfo && cellInfo.data.organizationId" :value="cellInfo.data.organizationId">
                            <organization-lookup :value="cellInfo.data.organizationId" :display="(d)=>d&&d.City" :view="true"></organization-lookup>
                        </div>
                    </template>

                    <DxColumn data-field="sendMail" caption="Verteiler" editor-type="dxCheckBox" data-type="boolean"
                              :validation-rules="[{ type: 'custom', message: 'Die E-Mail Adresse ist ungültig', validationCallback: checkEMail }]" />

                </dx-data-grid>
                <dx-tooltip :ref="tooltipRefName" position="right">
                    <div class="tooltipContent">                       
                        <div>{{currentOrg.name}}</div>
                        <div>{{currentOrg.streetAndNr}}</div>
                        <div>{{currentOrg.zipCode}} {{currentOrg.city}}</div>
                    </div>
                </dx-tooltip>
            </template>
        </dx-popup>
    </div>
</template>
<script>
    import { DxPopup, } from 'devextreme-vue/popup';
    import DxDataGrid, {
        DxColumn,
        DxEditing      
    } from 'devextreme-vue/data-grid';
    import { on } from 'devextreme/events';
    import DxTooltip from 'devextreme-vue/tooltip';
    import OrganizationLookup from '../components/organization-lookup.vue';
    import ContactLookup from '../components/contact-lookup.vue';
    import ListLookup from '../components/list-lookup.vue';
    import listApi from '../services/ListApi'
    import { guid } from '../services/Util'

    import organizationApi from '../services/OrganizationApi';
import { afterProcessingAsync, processor } from '../services/Processor';
    export default {
        components:{
            DxPopup,
            DxDataGrid,
            DxEditing,
            DxTooltip,
            DxColumn,
            OrganizationLookup,
            ContactLookup,
            ListLookup      
        },
        data() {
            return {
                popupVisible: false,
                editableValue: null,
                currentOrg: {},
                tooltipRefName: 'orgTooltip',

            };
        },
        created() {
          
        },
        methods: {
            handleRowReorder(e) 
            {
                const { fromIndex, toIndex } = e;
                const movedItem = this.editableValue.splice(fromIndex, 1)[0];
                this.editableValue.splice(toIndex, 0, movedItem);
                this.editableValue = [...this.editableValue];
                this.editableValue.push();
                console.log("this.editableValue", this.editableValue);
                Object.assign(this.$root.project.involvedContacts, this.editableValue);
            },
            addId(r) {
                r.data.sendMail = false;
                r.data.id = guid();
            },
            async fetchData() {
                if (processor.isDirty.value)
                    await afterProcessingAsync();

                console.log("fetching data");
                let assignmentList = await listApi.getByName("project_assignments");
                let assignments = await listApi.getListItems(assignmentList._id);
                let assignmentsWithData = assignments.filter(a => a.data && a.data.split(",").length == 2);
                console.log("fetching data",assignmentsWithData);

                this.editableValue = structuredClone(this.$root.project.involvedContacts);                
                let changed = false;
                for (let assignment of assignmentsWithData) {
                    let [contactField, organizationField] = assignment.data.split(",");
                    let contact = this.$root.project[contactField];
                    let organization = this.$root.project[organizationField];
                    if (contact && organization) {
                        let current = this.editableValue.find(ev => ev.contactId == contact);        

                        let removeNeeded = this.editableValue.find(ev => ev.listItemIds.includes(assignment._id) && ev.contactId != contact);
                        if (removeNeeded) {
                            console.log("[ProjectInvolved] remove needed (contact)", removeNeeded, current);
                            removeNeeded.listItemIds.splice(removeNeeded.listItemIds.indexOf(assignment._id), 1);
                            if (removeNeeded.listItemIds.length == 0) {
                                this.editableValue.splice(this.editableValue.findIndex(ev => ev.contactId == removeNeeded.id), 1);
                            }
                            changed = true;
                        }           

                        if (current) {
                                               
                            if (current.listItemIds.includes(assignment._id))
                                continue;

                            console.log("[ProjectInvolved] changed current", current);
                            current.listItemIds.push(assignment._id);
                            changed = true;
                        } else {
                            this.editableValue.push({
                                contactId: contact,
                                sendMail: false,
                                organizationId: organization,
                                listItemIds: [assignment._id],
                                id: guid()
                            })
                            console.log("[ProjectInvolved] added contact", organization,contact);
                            changed = true;
                        }
                    }else if(organization){
                        let current = this.editableValue.find(ev => ev.organizationId == organization && !ev.contactId);

                        let removeNeeded = this.editableValue.find(ev => ev.listItemIds.includes(assignment._id) && ev.organizationId != organization);
                        if (removeNeeded) {
                            console.log("[ProjectInvolved] remove needed (organization)", removeNeeded, current);
                            removeNeeded.listItemIds.splice(removeNeeded.listItemIds.indexOf(assignment._id), 1);
                            if (removeNeeded.listItemIds.length == 0) {
                                this.editableValue.splice(this.editableValue.findIndex(ev => ev.organizationId == removeNeeded.id), 1);
                            }
                            changed = true;
                        }                                

                        if (current) {                           
                            if (current.listItemIds.includes(assignment._id))
                                continue;

                            console.log("[ProjectInvolved] changed current", current);
                            current.listItemIds.push(assignment._id);
                            changed = true;
                        } else {
                            this.editableValue.push({
                                organizationId: organization,
                                sendMail: false,
                                listItemIds: [assignment._id],
                                id: guid()
                            })
                            console.log("[ProjectInvolved] added organization", organization);
                            changed = true;
                        }
                    }else {
                        console.error("[ProjectInvolved] No orga or contact?",assignment,contactField, organizationField)
                    }
                }
                if (changed) {
                    console.log("[ProjectInvolved] changed.");
                    Object.assign(this.$root.project.involvedContacts, this.editableValue);
                }
                    
            },
            async checkContactUnique(e) {
                if(!this.$refs.dataGrid)
                    return false;
                let grid = this.$refs.dataGrid.instance;
                let data = grid.getVisibleRows().filter(r=>!r.removed).map(r => r.data);
                if (e.value && data.filter(ev => ev.id != e.data.id).some(ev => ev.contactId == e.value)) {
                    throw new Error("Gleiche Person schon ausgewählt!")
                }
                return true;
            },
            async checkOrganizationUnique(e) {
                if (!this.$refs.dataGrid)
                    return false;
                if (e.data.contactId)
                    return true;
                let grid = this.$refs.dataGrid.instance;
                let data = grid.getVisibleRows().filter(r=>!r.removed).map(r => r.data);
                if (e.value && data.filter(ev => ev.id != e.data.id).some(ev => ev.organizationId == e.value && !ev.contactId)) {
                    throw new Error("Gleiche Firma schon ausgewählt!")
                }
                return true;
            },
            /// Obsolete
            async checkRequiresTrade() {

                // Gitlab Issue #93:
                // In der Beteiligtenliste kann aktuell kein Auftraggeber ohne Gewerk eingestellt werden.
                // Diese Abhängigkeit soll wieder entfernt werden.

                return true;
                /*
                let assignmentList = await listApi.getByName("project_assignments");
                let assignments = await listApi.getListItems(assignmentList._id);
                let executingCompanyItems = assignments.filter (a => a.data && a.data.includes("#selected")).map (i => i._id);

                let hasExecutingCompanyTag = executingCompanyItems.filter( i => e.value.includes(i)).length > 0;

                if (hasExecutingCompanyTag)
                {
                    return e.data.tradeId != null;
                }
                
                return true;
                */
            },
            async checkUnique(e) {
                let value = structuredClone(e.value);
                let assignmentList = await listApi.getByName("project_assignments");
                let assignments = await listApi.getListItems(assignmentList._id);
                let assignmentsWithData = assignments.filter(a => a.data && a.data.split(",").length == 2).map(a => a._id);
                if (!this.$refs.dataGrid)
                    return false;
                let grid = this.$refs.dataGrid.instance;
                let data = grid.getVisibleRows().filter(r=>!r.removed).map(r => r.data);
                let allOtherIds = structuredClone(data).filter(ev => ev.id != e.data.id).map(ev => ev.listItemIds).flat();
                let allOtherDataIds = allOtherIds.filter(i => assignmentsWithData.includes(i));
                let nonUnique = allOtherDataIds.filter(i => value.includes(i));
                if (nonUnique.length > 0) {
                    let fieldString = nonUnique.map(id => assignments.find(a => a._id == id).value).join(", ");
                    throw new Error(fieldString + " in anderer Zeile gesetzt!");
                }                    
                return true;
                
            },
            updateGrid() {
                // Use the ref to access the grid and refresh it
                this.$refs.dataGrid.instance.refresh(); // Triggers a grid re-render
            },
            async updateOrganization(cellInfo,value){
                if (cellInfo.data.contactId === value)
                    return;
                let contact = await organizationApi.getContact(cellInfo.row.data.contactId);
                let cs = cellInfo.component.option("editing.changes");
                cs.find(d=>d.key == cellInfo.row.key).data.organizationId=contact.organizationId;
                cellInfo.component.option("editing.changes",cs);
            },
            async clearContact(cellInfo, value) {
                console.log("CellInfo::", cellInfo, value);
                if(cellInfo.data.organizationId === value)
                    return;
                let cs = cellInfo.component.option("editing.changes");
                cs.find(d=>d.key == cellInfo.row.key).data.contactId=null;
                cellInfo.component.option("editing.changes",cs);
            },
            checkEMail(options) {                

                if (!options.data.sendMail)
                    return true;

                var eMail = options.data.contact ? options.data.contact.eMail : (options.data.organization ? options.data.organization.eMail : false);
                
                if (!eMail)
                {   // allow empty e-mail                    
                    return true;
                }

                return /^[\w.-]+@[\w.-]+\.\w+$/.test(eMail);
            },    
            async getOrganization(id) {
                console.log("looking up org-id:", id);
                var org = await organizationApi.get(id);
                console.log("found org:", org);
                return org;
            },
            async saving() {
                let assignmentList = await listApi.getByName("project_assignments");
                let assignments = await listApi.getListItems(assignmentList._id);
                let setFields = [];
                for(let element of this.editableValue){   
                    if(!element.listItemIds || element.listItemIds.length == 0)
                        continue;

                    let listItems = element.listItemIds.map(i=>assignments.find(a=>a._id == i)).filter(notNull=>notNull);
                    let fieldsToSet = listItems.filter(t => t.data&&t.data.split(",").every(d=>d in this.$root.project));
                    for (let field of fieldsToSet) {
                        let [contactField, organizationField] = field.data.split(",");
                        if (!contactField)
                            throw new Error("ContactField not set!")
                        if (!organizationField)
                            throw new Error("OrganizationField not set!")
                        if (setFields.includes(contactField)) {
                            throw new Error("ContactField should be unique");
                        }
                        this.$root.project[contactField] = element.contactId;
                        setFields.push(contactField);
                        if (setFields.includes(organizationField)) {
                            throw new Error("OrganizationField should be unique");
                        }
                        this.$root.project[organizationField] = element.organizationId;
                        setFields.push(organizationField);
                    }
                }
                this.$root.project.involvedContacts = this.editableValue;//.filter(e=>!removed.includes(e.id));
            },
            async onCellPrepared(e) {
                if (e.rowType === "data" && e.column.dataField === "organizationId"){
                    var self = this;
                    let handle = null;
                    e.data.organization = await organizationApi.get(e.data.organizationId);
                    e.data.contact = await organizationApi.getContact(e.data.contactId);

                    on(e.cellElement, "mouseover", async (args) => {                        
                        this.currentOrg = e.data.organization;                        

                    handle =  window.setTimeout ( () => {
                            self.$refs[this.tooltipRefName].instance.show(args.target);                        
                        },500);                        
                    });
                                        
                    on(e.cellElement, "mouseout", () => {
                        if (handle)
                        {
                            window.clearTimeout(handle);
                            handle = null;
                        }
                        self.$refs[this.tooltipRefName].instance.hide();                                                
                    });
                }
            }
        }
    };
</script>
