<template>
    <div class="area-content-body admin-system-templates">
        <div v-for="template in templates" v-bind:key="template.fileName" class="admin-system-template">
            <div class="admin-system-template-title">{{template.title}}</div>
            <image-viewer :attachment="{assignment:'template',assignmentType:'system',name:template.fileName}"
                          :allowed-types="template.docMimeType"
                          :allow-upload="true"
                          :allow-download="true"
                          :allow-drop="true"
                          :imgTrim="template.trim"
                          :imgOnePage="template.onePage"
                          height="400px"></image-viewer>
        </div>       
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import ImageViewer from '../components/image-viewer.vue';
    
    export default defineComponent({
        components: {
            ImageViewer
        },
        data() {
            return {
                templates: [
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "notice_template.docx",
                        title: "Vorankündigung"
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "protocol_template.docx",
                        title: "Protokolle"
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "protocol_grid_template.docx",
                        title: "Protokolle - Mangel",
                        trim: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "image_template.docx",
                        title: "Protokolle - Bild",
                        trim: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "site_order.docx",
                        title: "Baustellenordnung"
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "later_work_planning_template.docx",
                        title: "Planungsphase Spätere Arbeiten"
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "later_work_planning_root.docx",
                        title: "Spätere Arbeiten - Stamm",
                        trim: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "later_work_planning_step.docx",
                        title: "Spätere Arbeiten - Einzel",
                        trim: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "later_work_template.docx",
                        title: "Finale Unterlage Spätere Arbeiten"
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        fileName: "sige_plan_template.xlsx",
                        title: "SiGe-Plan Excel Vorlage"
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "sige_plan_planning_template.docx",
                        title: "SiGe-Plan Planung"
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "sige_plan_planning_danger.docx",
                        title: "SiGe-Plan Planung Tabelle",
                        trim: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "sige_plan_right_column_1.docx",
                        title: "SiGe-Plan Seitenleiste 1",
                        trim: true,
                        onePage: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "sige_plan_right_column_2.docx",
                        title: "SiGe-Plan Seitenleiste 2",
                        trim: true,
                        onePage: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "sige_plan_right_column_3.docx",
                        title: "SiGe-Plan Seitenleiste 3",
                        trim: true,
                        onePage: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "sige_plan_site_info_column.docx",
                        title: "SiGe-Plan Baustelleninfo Spalte",
                        trim: true,
                        onePage: true
                    },
                    {
                        docMimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        fileName: "sige_plan_site_info_image.docx",
                        title: "SiGe-Plan Baustelleninfo Bild"
                    },
                ]
            };
        },
        async created() {
        },
        methods: {
        },
    });
</script>

<style>
    .admin-system-templates{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: max-content;
        overflow: auto;
    }
        .admin-system-templates .image-viewer {
            background-color: transparent;
        }
    .admin-system-template{
        background-color: var(--color-accent);
        padding: 10px;
        margin: 10px;
        border-radius: var(--default-border-radius);
    }

    .admin-system-template-title {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 10px;
    }
</style>
