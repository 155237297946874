import { ref, watch } from 'vue'
import axios from './Axios';
import offlineService, { offlineProjectProtocolApi } from './OfflineService';
import { addProcess } from './Processor';

class ProjectProtocolApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;
        if (!window.Api) {
            window.Api = {};
        }
        window.Api.ProjectProtocol = this;
    }
    // eslint-disable-next-line
    async beforeSave(data) {
    }
    // eslint-disable-next-line
    async afterLoad(data) {
    }

    async get(id) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineProjectProtocolApi.get(id):  (await axios.get('/api/projectProtocols/' + id)).data;
        await this.afterLoad(result);
        return this.attachWatchers(result);
    }
    async getAll() {
        this.isLoading.value = true;
        // TODO: NO OFFLINE YET
        let result = (await axios.get('/api/projectProtocols/')).data;
        await this.afterLoad(result);
        this.isLoading.value = false;
        return this.attachWatchers(result);
    }
    async createNew(defaultValues) {
        let data = {
        };
        if (defaultValues)
            Object.assign(data, defaultValues);
        return this.attachWatchers(data);
    }
    async save(data) {
        let process = addProcess("Save ProjectProtocol: " + data.name);
        await this.beforeSave(data);
        let result = null;
        // If the service is not offline, then get the serverside data and also save to offline otherwise only offline.
        if (!offlineService.checkIsOffline)
            result = (await axios.post('/api/projectProtocols/', data)).data;
        result = await offlineProjectProtocolApi.save(result ? Object.assign(result, { _changed: '' }) : data).finally(() => process());
        return result;
    }
    async generateDocument(id) {
        let process = addProcess("Generating ProjectProtocolDoc: " + id);
        let attachment = await axios.post(`/api/projectProtocols/${id}/GenerateDocument`).finally(() => process());
        return attachment.data;
    }
    async finishDocument(id) {
        let process = addProcess("Finishing ProjectProtocolDoc: " + id);
        let attachment = await axios.post(`/api/projectProtocols/${id}/FinishDocument`).finally(() => process());
        return attachment.data;
    }
    async sendMail(id) {
        let process = addProcess("Finishing Sending Mail: " + id);
        let attachment = await axios.post(`/api/projectProtocols/${id}/SendMail`).finally(() => {
            process();
        });
        return attachment.data;
    }
    async cleanUp(id) {
        let process = addProcess("CleanUp Protocol: " + id);
        let bytesResp = await axios.get(`/api/projectProtocols/${id}/CleanUp`).finally(() => {
            process();
        });
        return bytesResp.data;
    }
    async delete(id) {
        let process = addProcess("Delete ProjectProtocol: " + id);
        // TODO: NO OFFLINE YET
        await axios.delete('/api/projectProtocols/' + id).finally(() => process());
    }
    attachWatchers(data) {
        if (Array.isArray(data))
            return data.map(i => this.attachWatchers(i));
        let dataRef = ref(data);
        this.isLoading.value = false;
        watch(dataRef.value, async () => {
            if (this.isSaving)
                return;
            this.isDirty.value = true;
            this.isSaving = true;
            let newData = await this.save(dataRef.value);
            Object.assign(dataRef.value, newData);
            setTimeout(() => {
                this.isSaving = false;
            }, 1)
            this.isDirty.value = false;
        });
        return dataRef.value;
    }
}

export { ProjectProtocolApi }
let projectProtocolApi = new ProjectProtocolApi();
export default projectProtocolApi