<template>
    <div>
        <span class="head-toolbar-button-compact" :class="{'has-data':hasData}" @click="popupVisible=!popupVisible" title="Datei an Mail anfügen">attach_file</span>
        <DxPopup v-model:visible="popupVisible" :width="900" :height="450" :hide-on-outside-click="true" :show-close-button="true" title="Mail Anlagen" :deferRendering="false">
            <template #content>
                <div class="att-inner-content">
                    <div class="att-info">
                        <slot name="info"></slot>
                    </div>                    
                    <attachment-list ref="att_list" :attachment-query="{assignmentType:'project',assignment:$root.project._id,type:subtype?`Mail_${subtype}`:'Mail'}" :allow-upload="true" :allow-download="true" :allow-delete="true" :allow-description="false" :show-size="true" v-model:hasData="hasData"></attachment-list>
                </div>                
            </template>
        </DxPopup>
    </div>
</template>
<script>
    import { DxPopup } from 'devextreme-vue/popup';
    import AttachmentList from '../components/attachment-list.vue';
    export default {
        components:{
            DxPopup,
            AttachmentList
        },
        props:['subtype','dontShowActive'],
        data() {
            return {
                hasData: false,
                popupVisible:false
            };
        },
        methods: {
            reload() {
                this.$refs.att_list.loadAttachments();
            }
        }
    };
</script>
<style scoped>
    .has-data {
        background-color: var(--color-accent);
    }
    .att-info{
        padding: 10px;
    }
    .att-inner-content{
        display:grid;
        grid-template-rows: max-content 1fr;
    }
</style>